import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { HttpService } from 'src/app/common/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private httpService: HttpService, private toast: ToastService) {}

  /*   public get(query?: string): Observable<any> {
    return this.httpService.get('notices' + query);
  } */
  public delete(id: number): Observable<boolean> {
    return this.httpService.delete('galleries/' + id).pipe(
      map((deleted: boolean) => {
        if (deleted) {
          this.toast.success('Galeria foi removida com sucesso!');
        }
        return deleted;
      })
    );
  }

  get(galleryType: 'images' | 'videos' | 'podcasts'): Observable<Gallery> {
    return this.httpService.get('galleries/' + galleryType);
  }
}

export const GalleryLabels = {
  videos: 'vídeos',
  images: 'imagens',
  podcasts: 'podcasts',
};

export interface Gallery {
  id?: number;
  type: 'videos' | 'images' | 'podcasts';
  name: string;
  caption: string;
  media: GalleryVideo[] | GalleryImage[] | GalleryPodcast[];
  created_at: Date;
}

export interface GalleryVideo {
  id?: number;
  path: string;
  is_main?: boolean;
  caption: string;
  created_at: Date;
}
export interface GalleryImage {
  id?: number;
  path: string;
  path_resized: string;
  is_main: boolean;
  caption: string;
}

export interface GalleryPodcast {
  id?: number;
  path: string;
  caption: string;
  created_at: Date;
}

export const fakeGallery: Gallery = {
  id: -1,
  type: 'images',
  name: '',
  caption: '',
  media: [],
  created_at: new Date(),
};

export function instanceOfGallery(object: any): object is Gallery {
  return (
    'id' in object &&
    'type' in object &&
    'name' in object &&
    'caption' in object &&
    'media' in object &&
    'created_at' in object
  );
}
