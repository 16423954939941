import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { HttpService } from 'src/app/common/services/http.service';
import { instanceOfRole, Role, RoleLabel } from './roles';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  
  constructor(private httpService: HttpService, private toast: ToastService) {}

  get(): Observable<Role[]> {
    return this.httpService.get('roles').pipe(
      map((roles: Role[]) => {
        if (instanceOfRole(roles[0])) {
          return roles;
        } else {
          console.error('object instance not compatible with Role type');
          return null;
        }
      })
    );
  }

  getLabels(): Observable<RoleLabel[]> {
    return this.httpService.get('roles/labels');
  }

  public delete(id: number): Observable<boolean> {
    return this.httpService.delete('roles/' + id).pipe(
      map((deleted: boolean) => {
        if (deleted) {
          this.toast.success('Cargo foi removido com sucesso!');
        }
        return deleted;
      })
    );
  }
}
