import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { HttpService } from 'src/app/common/services/http.service';
import { User, UserLabel } from './user';
@Injectable({
  providedIn: 'root',
})
export class UsersService {

  public linklist = [
    {
      icon: 'bt bt-people',
      title: 'Usuários',
      url: '/usuarios',
    },

  ];

  constructor(private httpService: HttpService, private toast: ToastService) {}

  get(query: string = ''): Observable<User[]> {
    return this.httpService.get('users' + query);
  }

  getLabels(): Observable<UserLabel[]> {
    return this.httpService.get('users/labels');
  }

  public delete(id: number): Observable<boolean> {
    return this.httpService.delete('users/' + id).pipe(
      map((deleted: boolean) => {
        if (deleted) {
          this.toast.success('Usuário foi eliminado com sucesso!');
        }
        return deleted;
      })
    );
  }
}
