import { Permission } from '../permissions/permissions';

export interface Role {
  id?: number;
  name?: string;
  display_name: string;
  description: string;
  avatar?: string;
  permissions?: Permission[];
}
export interface RoleLabel {
  id: number;
  display_name: string;
}
/**
 * Check if object parameter is Role type
 * @param object any
 * @returns object is Role
 */
export function instanceOfRole(object: any): object is Role {
  return (
    'id' in object &&
    'name' in object &&
    'display_name' in object &&
    'avatar' in object &&
    'description' in object
  );
}
